import * as React from "react";

export const ImproveReadingLogo = (props) => (
  <svg
    width={204}
    height={158}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.78 154.121s-36.882-4.533-30.81-34.521c6.069-29.987 9.514-31.714-1.54-66.906-11.055-35.192 58.627-74.452 93.174-38.349 34.545 36.103 53.397 12.541 77.677 33.447 24.28 20.904 8.291 81.668-18.655 106.329H48.779Z"
      fill="#34566A"
    />
    <path
      d="m78.574 45.805 41.315 2.628 10.905 36.842-56.124 1.95 3.904-41.42Z"
      fill="#744442"
    />
    <path
      d="M28.616 117.476c8.25-3.997 130.391-1.998 140.474 0 10.083 1.999 41.134 33.803 33.801 37.452-7.333 3.65-197.878 4.519-202.347 0-4.469-4.518 19.822-33.455 28.072-37.452Z"
      fill="#34566A"
    />
    <path
      d="M79.112 65.891c0 2.167 1.043 3.138 2.48 3.923.69.314 1.146.133 1.517.47 4.497 4.1.965-2.391.965-4.393 0-2.167-1.11-3.923-2.481-3.923-1.37 0-2.481 1.756-2.481 3.923Z"
      fill="#D99687"
    />
    <path
      d="M80.797 63.837a.063.063 0 1 1 .01-.127l-.01.127Zm2.18 2.107-.061.02v-.002l-.002-.005-.007-.02a5.4 5.4 0 0 0-.152-.376 6.513 6.513 0 0 0-.52-.944l.105-.07a6.63 6.63 0 0 1 .656 1.264 3.377 3.377 0 0 1 .038.106l.002.006v.001l-.06.02Zm-.743-1.327c-.23-.346-.588-.541-.892-.65a2.586 2.586 0 0 0-.535-.13h-.01l.005-.064.006-.063h.003l.01.002.034.004a2.708 2.708 0 0 1 .53.131c.317.114.703.323.955.7l-.106.07Z"
      fill="#FFF8F8"
      fillOpacity={0.38}
    />
    <path
      d="M82.22 67.445a.063.063 0 1 1-.109.064l.11-.064Zm-.614-3.345.062.013v.002l-.001.005a1.2 1.2 0 0 0-.02.106 6.488 6.488 0 0 0-.06 1.393l-.126.009a6.626 6.626 0 0 1 .062-1.422 3.445 3.445 0 0 1 .02-.117l.001-.002.062.013Zm-.019 1.52c.028.423.185.878.338 1.23a6.338 6.338 0 0 0 .29.584l.004.008a.02.02 0 0 0 .001.002l-.054.033-.055.032-.002-.003-.005-.009a2.259 2.259 0 0 1-.087-.158 6.485 6.485 0 0 1-.209-.438c-.154-.357-.318-.828-.347-1.273l.126-.008Z"
      fill="#FFF8F8"
      fillOpacity={0.38}
    />
    <path
      d="M122.838 64.927c0 2.166-1.043 3.138-2.482 3.923-.689.314-1.145.133-1.516.47-4.497 4.1-.965-2.392-.965-4.393 0-2.167 1.111-3.923 2.481-3.923 1.371 0 2.482 1.756 2.482 3.923Z"
      fill="#D99687"
    />
    <path
      d="M118.004 62.691c0 6.04-1.027 10.975-3.744 14.11-3.361 3.878-10.871 7.599-13.166 7.599-1.57 0-10.267-3.257-12.079-5.79-1.812-2.532-4.831-9.165-6.522-15.919-3.046-12.168 8.328-21.708 18.601-21.708s16.91 9.72 16.91 21.708Z"
      fill="#F0AA90"
    />
    <path
      d="M105.316 74.033c-1.498 3.251-4.139 2.457-5.082 1.579.514 1.453 1.964 1.33 2.927 1.208 1.806-.23 2.499-2.202 2.155-2.787Z"
      fill="#B6634E"
    />
    <path
      d="M102.964 66.418c.232 1.465-.244 3.431-1.886 3.168-.979-.156-1.345-1.173-1.115-2.055.6-1.284 1.707-2.818 2.075-4.957.244.783.669 2.218.926 3.844Z"
      fill="#B7624E"
    />
    <path
      d="M119.503 42.57c.736 2.276.931 12.484-1.399 21.264-1.349-5.311-4.986-17.179-6.782-17.668-3.066 3.036-6.852.604-8.423-.438-1.135 2.781-10.058 4.68-12.387 1.752-2.673 3.17-4.478 6.056-8.034 16.354-2.33-4.227-5.036-18.229-4.3-21.264 1.655-6.83 10.394-13.333 21.704-13.333 11.311 0 17.344 6.287 19.621 13.333Z"
      fill="#744442"
    />
    <ellipse
      rx={3.436}
      ry={1.616}
      transform="matrix(-.97198 .23507 .23575 .97181 108.953 59.313)"
      fill="#000"
    />
    <ellipse
      rx={3.464}
      ry={1.41}
      transform="matrix(-.98258 .18584 .18639 .98248 108.973 59.53)"
      fill="#fff"
    />
    <path
      d="M121.748 62.871a.063.063 0 1 0-.011-.126l.011.126Zm-2.179 2.108.06.02v-.002l.002-.005.007-.02.03-.081a6.442 6.442 0 0 1 .643-1.238l-.106-.07a6.622 6.622 0 0 0-.656 1.263 1.357 1.357 0 0 0-.03.084l-.008.022-.002.005-.001.002.061.02Zm.742-1.327c.231-.346.588-.542.892-.651a2.6 2.6 0 0 1 .535-.129h.01l-.005-.064-.006-.063h-.003l-.009.002-.035.004a2.705 2.705 0 0 0-.53.131c-.317.114-.703.323-.955.7l.106.07Z"
      fill="#FFF8F8"
      fillOpacity={0.38}
    />
    <path
      d="M120.325 66.48a.063.063 0 0 0 .022.086c.03.018.069.008.087-.022l-.109-.064Zm.614-3.345-.062.013v.002l.001.005.004.022.015.084a6.488 6.488 0 0 1 .061 1.393l.126.009a6.524 6.524 0 0 0-.077-1.51l-.005-.023-.001-.006v-.002l-.062.013Zm.019 1.52c-.028.423-.185.878-.338 1.23a6.419 6.419 0 0 1-.289.584l-.005.008-.001.002.054.033.055.032.001-.003.006-.009a6.909 6.909 0 0 0 .296-.596c.154-.357.318-.828.347-1.273l-.126-.008Z"
      fill="#FFF8F8"
      fillOpacity={0.38}
    />
    <path
      opacity={0.6}
      d="M104.657 53.78c.245-.215 6.494-.09 7.932.157 1.437.248 3.222 2.43 3.222 2.43s-1.189-.681-2.576-1.015c-1.388-.334-8.077-.118-8.458-.3-.381-.183-.364-1.059-.12-1.273ZM96.353 53.801c-.268-.162-6.083 1.09-7.38 1.587-1.296.498-2.554 2.918-2.554 2.918s.983-.87 2.217-1.441c1.234-.573 7.523-1.575 7.845-1.82.321-.244.14-1.082-.128-1.244Z"
      fill="#000"
    />
    <path
      d="M113.714 56.525s-.131.482-.372.723c.603 0 .86-.236.86-.236s-1.144 1.492-1.948 1.563c0 0-.045-.213-.12-.241-.794-.298-1.208-.603-1.208-.603s.845-.121 1.208-.362c.362-.241 1.092-.844 1.092-.844s-.368.482-.247.482c.242 0 .735-.482.735-.482Z"
      fill="#000"
    />
    <ellipse
      rx={1.614}
      ry={1.014}
      transform="rotate(-30.438 211.086 -132.116) skewX(.076)"
      fill="#E89180"
    />
    <ellipse
      rx={1.615}
      ry={1.013}
      transform="matrix(-.95643 -.29197 -.2928 .95618 82.425 90.87)"
      fill="#E89180"
    />
    <path
      d="M92.67 98.233c-2.547-1.345-5.776-1.567-6.896-1.812 1.483-.48 2.676-.215 3.63 0 1.089.245 2.897 1.191 3.267 1.812Z"
      fill="#B6634E"
    />
    <ellipse
      rx={2.464}
      ry={2.069}
      transform="matrix(-.97676 .21452 .21513 .97654 82.754 86.274)"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.433 87.194a1.47 1.47 0 0 1-.093-.307l.093.307Zm.803.84c.55.298 1.29.402 2.052.236 1.398-.305 2.348-1.399 2.123-2.444-.226-1.045-1.542-1.646-2.94-1.341-.837.182-1.514.648-1.873 1.217a1.209 1.209 0 0 1 .684 2.318l-.046.014Z"
      fill="#fff"
    />
    <ellipse
      rx={2.464}
      ry={2.069}
      transform="matrix(-.98691 .16163 .1621 .98672 93.086 85.314)"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.021 84.555h.01a1.206 1.206 0 0 1 1.251 1.164c.02.572-.36 1.066-.89 1.211.54.368 1.312.534 2.126.402 1.411-.23 2.417-1.272 2.247-2.327-.17-1.055-1.452-1.724-2.863-1.494-.808.132-1.483.53-1.88 1.044Z"
      fill="#fff"
    />
    <path
      d="M87.293 92.992c-2.134-.21-.688-4.07.301-5.975.255.67-.113 2.04.446 3.261.54 1.178.699 2.857-.747 2.714Z"
      fill="#B7624E"
    />
    <path
      d="M84.782 82.35a.135.135 0 0 0 .101-.09c.067-.206-.147-.39-.33-.505-.694-.439-1.516-.957-2.217-.745-.965.291-1.98 1.352-2.294 1.977.564-.232 1.56-1.378 2.398-1.561.326-.071.638.052.92.232.455.29.902.835 1.422.693ZM88.976 84.486a.933.933 0 0 1-.694-.358c-.13-.173-.014-.404.202-.395.25.011.562.108.833-.018 1.043-.728 1.96-1.262 2.814-1.457.73 0 .864.02 1.355.209-.827-.15-2.24.457-3.17 1.203-.421.338-.8.842-1.34.816Z"
      fill="#12121C"
    />
    <ellipse
      rx={3.436}
      ry={1.616}
      transform="matrix(.99977 .02748 -.02755 .99948 93.152 60.5)"
      fill="#000"
    />
    <ellipse
      rx={3.464}
      ry={1.41}
      transform="matrix(.99985 -.02289 .02295 .99962 93.179 60.717)"
      fill="#fff"
    />
    <path
      d="M95.563 61.463a1.207 1.207 0 0 0-2.407.2c.055.663 1.232.495 1.232.495s1.23-.032 1.175-.695ZM110.112 60.267a1.207 1.207 0 0 0-2.408.2c.056.664 1.233.496 1.233.496s1.23-.032 1.175-.696ZM88.131 59.083s.013.126.3.312c-.59.125-.584.055-.584.055s1.124 1.113 1.924 1.016c0 0 0-.218.068-.261.715-.457 1.055-.842 1.055-.842s-.852.058-1.257-.102c-.404-.16-1.301-.786-1.301-.786s.517.583.399.608c-.236.05-.604 0-.604 0Z"
      fill="#000"
    />
    <path
      d="M142.844 57.687s-38.96 4.843-41.299 15.914c-.433 6.4 0 5.708 0 5.708s12.967 3.306 23.734-1.21c10.68-4.481 21.809-14.79 21.809-14.79l-4.244-5.622Z"
      fill="#DCDBEC"
    />
    <path
      d="M59.864 57.601s38.96 4.843 41.298 15.914c.433 6.4.279 5.967.279 5.967s-13.246 3.048-24.013-1.47c-10.68-4.48-21.809-14.79-21.809-14.79l4.245-5.62Z"
      fill="#DCDBEC"
    />
    <path fill="#2C50DB" d="M93.904 79.223h14.725v60.368H93.904z" />
    <path
      d="M52.873 59.154S93.47 70.122 93.909 79.176c.44 9.053 0 59.892 0 59.892S81.401 128.97 73.72 126.01c-7.68-2.96-18.652-7.138-18.652-7.138l-2.195-59.718Z"
      fill="#365EF6"
    />
    <path
      d="M149.168 61.838s-41.383 9.452-41.822 18.49c-.439 9.038.683 58.224.683 58.224s13.706-6.818 21.39-9.773c7.683-2.954 19.749-6.14 19.749-6.14V61.838Z"
      fill="#1D369A"
    />
    <path
      d="M154.302 80.33c.611 1.557-.248 4.476-4.504 6.143-4.256 1.667-8.676 2.712-9.288 1.156-.611-1.557 3.132-3.857 6.6-5.837 3.467-1.98 6.58-3.017 7.192-1.461Z"
      fill="#F0AA90"
    />
    <path
      d="M155.339 87.944c.097 1.67-.954 3.143-5.519 3.407-4.564.264-9.09-.114-9.187-1.783-.097-1.669 4.177-2.694 8.089-3.5 3.912-.806 6.52.207 6.617 1.876Z"
      fill="#F0AA90"
    />
    <path
      d="M155.22 93.901c-.396 1.56-1.752 2.619-5.87 1.577-4.117-1.042-8.015-2.662-7.619-4.223.397-1.56 4.476-1.3 8.172-.94 3.697.361 5.713 2.026 5.317 3.586Z"
      fill="#F0AA90"
    />
    <path
      d="M154.105 97.376c-.287 1.128-1.271 1.891-4.263 1.134-2.993-.757-5.827-1.933-5.54-3.06.286-1.128 3.25-.935 5.936-.671s4.153 1.47 3.867 2.597Z"
      fill="#F0AA90"
    />
    <path
      d="M151.287 85.952a.09.09 0 1 0-.119-.137l.119.137Zm-9.762 2.18-.028.087h.002l.004.002.016.004a3.324 3.324 0 0 0 .292.064c.206.036.516.074.935.085.837.022 2.106-.066 3.846-.5l-.044-.177c-1.726.431-2.978.517-3.798.496a6.336 6.336 0 0 1-.908-.082 3.18 3.18 0 0 1-.285-.064h-.003l-.029.086Zm5.067-.258c1.738-.434 2.909-.912 3.647-1.283.369-.185.629-.343.799-.456.084-.057.146-.102.187-.133l.046-.036a.38.38 0 0 1 .015-.012l.001-.001-.059-.07-.06-.068-.002.002-.01.008a3.174 3.174 0 0 1-.219.158 7.363 7.363 0 0 1-.78.446c-.725.364-1.883.837-3.609 1.268l.044.177Z"
      fill="#000"
    />
    <path
      d="M142.064 90.81s1.765.898 5.337.928c3.571.031 4.986-.674 4.986-.674M143.134 93.241s1.44 1.047 4.909 1.895c3.47.848 5.082.718 5.082.718"
      stroke="#000"
      strokeWidth={0.182}
      strokeLinecap="round"
    />
    <path
      d="M48.966 81.026c-.612 1.556.248 4.475 4.504 6.142 4.256 1.667 8.676 2.712 9.287 1.156.612-1.556-3.131-3.857-6.599-5.837-3.468-1.98-6.58-3.017-7.192-1.461Z"
      fill="#F0AA90"
    />
    <path
      d="M47.928 88.638c-.096 1.67.955 3.143 5.52 3.407 4.563.264 9.09-.114 9.186-1.783.097-1.669-4.176-2.694-8.089-3.5-3.912-.806-6.52.207-6.617 1.876Z"
      fill="#F0AA90"
    />
    <path
      d="M48.048 94.597c.396 1.56 1.752 2.618 5.87 1.576 4.116-1.042 8.014-2.662 7.618-4.223-.396-1.56-4.475-1.3-8.172-.94-3.696.361-5.712 2.026-5.316 3.587Z"
      fill="#F0AA90"
    />
    <path
      d="M49.163 98.07c.286 1.128 1.27 1.891 4.263 1.134 2.992-.757 5.826-1.933 5.54-3.06-.286-1.128-3.25-.935-5.937-.67-2.686.263-4.152 1.469-3.866 2.596Z"
      fill="#F0AA90"
    />
    <path
      d="M51.98 86.646a.091.091 0 0 1 .12-.137l-.12.137Zm9.762 2.18.028.087h-.002l-.004.002-.015.005-.06.015c-.052.013-.129.03-.232.049a6.501 6.501 0 0 1-.935.084c-.837.022-2.106-.066-3.846-.5l.044-.177c1.725.431 2.978.517 3.797.496.41-.01.711-.048.909-.082a3.156 3.156 0 0 0 .272-.06l.013-.004h.003l.028.086Zm-5.066-.258c-1.739-.434-2.91-.912-3.647-1.282a7.58 7.58 0 0 1-.799-.457 3.729 3.729 0 0 1-.248-.181l-.001-.001.059-.07.06-.068.002.002.01.008.041.033c.038.029.097.071.178.126.163.108.417.263.78.445.724.364 1.882.837 3.609 1.268l-.044.177Z"
      fill="#000"
    />
    <path
      d="M61.203 91.505s-1.765.897-5.336.928c-3.571.03-4.987-.675-4.987-.675M60.133 93.935s-1.44 1.047-4.909 1.895c-3.469.848-5.08.718-5.08.718"
      stroke="#000"
      strokeWidth={0.182}
      strokeLinecap="round"
    />
  </svg>
);
