import * as React from "react";

export const BuildYourVocabularyLogo = (props) => (
  <svg
    width={199}
    height={139}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.985 131.706s-35.137 1.819-33.395-23.815c1.741-25.635 4.741-27.577-10.24-54.922-14.98-27.345 45.12-70.206 82.222-45.65 37.101 24.558 51.659 2.295 77.148 15.87 17.062 9.087 19.573 35.615 12.772 59.477-6.258 21.956-28.547 32.961-51.076 36.585l-77.431 12.455Z"
      fill="#34566A"
    />
    <rect
      x={7.972}
      y={65.134}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#FFCD00"
    />
    <path
      d="M18.844 75.174h.026-.026Zm-3.063 3.28c.984 2.428 3.533 1.88 4.716 1.488.549-1.096 1.488-4.141 1.819-5.899.374-1.975-.6-3.35-2.706-2.558-.122.453-.461 2.454-.74 3.69-.165.713-.322 1.4-.478 2.088h-.079c-.121-1.001-.417-2.933-.791-4.699-.41-1.958-2.854-1.305-3.167-.191.33 1.827.965 4.933 1.426 6.081Z"
      fill="#303030"
    />
    <rect
      x={31.876}
      y={65.134}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#FFCD00"
    />
    <path
      d="M42.318 71.259c-2.392.009-3.55 1.314-4.098 2.54-.409.897-.478 2.184-.217 3.307.27 1.192.904 2.123 2.01 2.689 1.157.6 2.384.695 3.637.391 1.548-.365 2.566-1.323 3.036-2.837.348-1.148.252-2.28-.157-3.393-.26-.687-1.244-2.715-4.21-2.697Zm.044 3.115c.618 0 1.174.565 1.113 1.549-.052.983-.617 1.34-1.078 1.366-.453.034-1.123-.322-1.184-1.332-.07-1.035.505-1.583 1.149-1.583Z"
      fill="#303030"
    />
    <rect
      x={55.778}
      y={65.134}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#FFCD00"
    />
    <path
      d="M67.732 74.182c1.14.462 2.697-.417 2.262-2.08-1.87-1.078-3.645-1.078-5.099-.373-1.087.54-1.888 1.296-2.28 2.453-.347 1.027-.312 2.063-.025 3.09.426 1.478 1.374 2.453 2.888 2.844 1.01.253 2.532.314 3.463-.121 2.22-1.044 1.419-3.15.192-3.385-.21.13-.957.626-1.549.705-.888.13-1.74-.2-1.88-1.227-.095-.679.035-1.34.653-1.723.47-.296.844-.313 1.375-.183Z"
      fill="#303030"
    />
    <rect
      x={79.675}
      y={65.134}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#FFCD00"
    />
    <path
      d="M85.99 77.506c-.374 1.975.662 3.341 2.802 2.558.122-.452.26-.887.409-1.253.557.018 1.166-.017 1.714.009.687 2.384 3.341 1.305 3.463.357-.33-1.828-1.236-4.89-1.706-6.039-.991-2.419-3.584-1.87-4.776-1.479-.557 1.088-1.566 4.098-1.906 5.847Zm3.55-1.183c.165-.714.33-1.392.487-2.08.026 0 .053 0 .079-.008.165.696.34 1.383.504 2.088h-1.07Z"
      fill="#303030"
    />
    <rect
      x={103.577}
      y={65.134}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#FFCD00"
    />
    <path
      d="M116.413 75.548c.078-.07.13-.113.174-.156.974-.87 1.009-2.663-.374-3.585-1.045-.696-4.125-.74-5.517.087-.156 1.731-.235 4.316-.078 5.682.2 1.8 1.314 2.75 3.358 2.75 2.28 0 3.62-1.106 3.646-2.846.017-.948-.435-1.662-1.209-1.932Zm-2.785 2.14c0-.312-.008-.626.009-.93.157-.157.461-.14.635-.105.218.053.383.21.4.444.027.279-.069.505-.321.61a.84.84 0 0 1-.723-.018Zm-.061-3.07c-.087-.27-.069-.592 0-.758.131-.174.731-.174.749.305.008.557-.479.609-.749.452Z"
      fill="#303030"
    />
    <rect
      x={127.475}
      y={65.134}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#FFCD00"
    />
    <path
      d="M142.111 77.175c.278-2.427.174-2.853.06-4.045-.191-2.132-2.758-1.888-3.436-1.192.252 1.313.156 4.21-.053 5.255-.182.957-1.235.8-1.366.035-.208-1.253-.121-4.212-.174-4.664-.209-1.584-2.801-1.27-3.184-.844-.244 1.801-.4 3.907-.174 5.49.383 2.81 2.427 3.159 4.246 3.159 1.81 0 3.819-.975 4.081-3.194Z"
      fill="#303030"
    />
    <rect
      x={151.375}
      y={65.134}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#FFCD00"
    />
    <path
      d="M163.747 80.108c1.218-.079 2.218-1.567 1.513-2.88-1.226-.148-2.81-.166-3.602-.105.035-1.087.035-2.862-.069-3.889-.235-2.132-2.28-2.114-3.08-1.392-.34 2.149-.218 5.15-.035 6.378.252 1.705 1.679 1.888 2.575 1.888 0 0 1.471.078 2.698 0Z"
      fill="#303030"
    />
    <rect
      x={31.876}
      y={40.276}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#3B61F0"
    />
    <path
      d="M46.49 52.33c.277-2.418.173-2.843.06-4.03-.19-2.124-2.747-1.881-3.423-1.188.251 1.309.156 4.195-.052 5.235-.182.953-1.231.797-1.361.035-.208-1.248-.121-4.195-.173-4.646-.208-1.577-2.791-1.265-3.172-.84-.243 1.794-.399 3.891-.174 5.468.382 2.8 2.418 3.146 4.23 3.146 1.802 0 3.804-.97 4.064-3.18Z"
      fill="#fff"
    />
    <rect
      x={7.978}
      y={40.276}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#3B61F0"
    />
    <path
      d="M20.799 50.709c.078-.07.13-.113.173-.156.97-.867 1.005-2.652-.373-3.57-1.04-.694-4.108-.737-5.495.086-.155 1.725-.233 4.299-.077 5.66.199 1.793 1.308 2.738 3.345 2.738 2.27 0 3.605-1.1 3.631-2.834.017-.945-.433-1.655-1.204-1.924Zm-2.774 2.132c0-.312-.008-.624.009-.927.156-.156.46-.139.633-.104.216.052.38.208.398.442.026.277-.07.502-.32.606a.836.836 0 0 1-.72-.017Zm-.06-3.06c-.087-.268-.07-.589 0-.753.13-.174.727-.174.745.303.008.555-.477.607-.745.45Z"
      fill="#fff"
    />
    <rect
      x={79.675}
      y={40.276}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#3B61F0"
    />
    <path
      d="M92.032 55.25c1.214-.078 2.21-1.56 1.508-2.868-1.222-.148-2.799-.165-3.588-.104.035-1.084.035-2.852-.069-3.874-.234-2.124-2.27-2.106-3.068-1.387-.338 2.14-.217 5.13-.035 6.353.252 1.698 1.673 1.88 2.566 1.88 0 0 1.464.078 2.686 0Z"
      fill="#fff"
    />
    <rect
      x={55.778}
      y={40.276}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#3B61F0"
    />
    <path
      d="M67.77 48.308c-.32-2.08-2.261-2.08-3.067-1.369-.278 1.057-.182 5.841-.035 6.613.381 1.95 2.28 2.236 3.007 1.447.2-1.456.252-5.65.096-6.69Z"
      fill="#fff"
    />
    <rect
      x={103.577}
      y={40.276}
      width={21.035}
      height={21.124}
      rx={1}
      fill="#3B61F0"
    />
    <path
      d="M118.111 50.735c0-1.863-.659-3.05-2.054-3.787-1.144-.607-4.255-.763-5.694.086-.147 2.609-.156 4.784-.026 6.197.147 1.664 1.144 2.227 3.224 2.227 3.761 0 4.55-3.137 4.55-4.723Zm-4.342 2.297c-.13 0-.399-.044-.407-.312-.018-.98 0-3.363 0-3.909.693-.13 1.802.173 1.794 1.872 0 1.76-.72 2.331-1.387 2.349Z"
      fill="#fff"
    />
    <path
      d="M155.902 96.385a2.223 2.223 0 0 1 1.946-2.468l16.479-1.953a2.219 2.219 0 0 1 2.465 1.945l1.947 16.565a2.223 2.223 0 0 1-1.946 2.468l-16.479 1.953a2.218 2.218 0 0 1-2.465-1.944l-1.947-16.566Z"
      fill="#FFCD00"
    />
    <path
      d="M163.119 103.738c-.141 2.005 1.047 3.24 3.081 2.21.068-.463.156-.912.26-1.292.555-.048 1.156-.155 1.703-.193.961 2.287 3.47.903 3.481-.054-.542-1.775-1.798-4.71-2.399-5.796-1.267-2.286-3.778-1.436-4.916-.907-.426 1.146-1.077 4.255-1.21 6.032Zm3.387-1.594c.081-.728.166-1.421.241-2.122.026-.003.052-.006.077-.018.246.672.499 1.334.745 2.014l-1.063.126Z"
      fill="#303030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M198.321 132.641s-8.956-9.561-9.52-12.655c-.562-3.093-2.197-7.255-3.999-7.93-1.801-.675-5.576-.506-5.576-.506s-4.278-6.074-4.674-7.537c-.393-1.462-1.577 2.644-.62 4.612.958 1.969 4.057 6.918 3.888 7.424-.169.507-1.466 0-1.746 1.631-.282 1.631-.902 3.319.393 4.05 1.295.731 7.884 3.318 9.18 3.318 0 0 6.422 12.711 7.491 12.936 1.07.225 5.183-5.343 5.183-5.343Z"
      fill="#EFAA8F"
    />
    <path
      d="M198.321 132.641s-8.956-9.561-9.52-12.655c-.562-3.093-2.197-7.255-3.999-7.93-1.801-.675-5.576-.506-5.576-.506s-4.278-6.074-4.674-7.537c-.393-1.462-1.577 2.644-.62 4.612.958 1.969 4.057 6.918 3.888 7.424-.169.507-1.466 0-1.746 1.631-.282 1.631-.902 3.319.393 4.05 1.295.731 7.884 3.318 9.18 3.318 0 0 6.422 12.711 7.491 12.936 1.07.225 5.183-5.343 5.183-5.343"
      stroke="#000"
      strokeWidth={0.139}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M177.901 116.562s.787 2.129.787 3.199c0 1.071-2.561-1.946-2.561-1.946s.039-.686.97-.859c.729-.135.804-.394.804-.394Z"
      fill="#303030"
    />
    <path
      d="M177.901 116.562s.787 2.129.787 3.199c0 1.071-2.561-1.946-2.561-1.946s.039-.686.97-.859c.729-.135.804-.394.804-.394"
      stroke="#000"
      strokeWidth={0.139}
    />
  </svg>
);
