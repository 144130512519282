import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const TutoringIntroduction = () => {
  const { palette } = useTheme();
  const { desktop, mobile } = useStyles();
  return (
    <>
      <Box px={8} pt={10} pb={0} className={desktop}>
        <Box width="50%">
          <Typography variant="h1" color={palette.common.white}>
            Personalized Online Tutoring
          </Typography>
        </Box>
        <Box width="70%" pt={2} pb={3}>
          <Typography
            variant="body3"
            component="p"
            color={palette.common.white}
          >
            Word Voyage Online Tutoring is the perfect solution for students
            needing a structured program with regular feedback from a teacher.
            Our experienced tutors provide one-on-one, <b>hands-on instruction</b> in
            vocabulary and grammar. Lessons are tailored to meet the student's
            level. Regular feedback and face-to-face sessions are available to
            keep the student on track and moving forward with skills.
            <br/><br/>
            For a free consultation on your child's specific needs, please  
            complete <a href="https://go.wordvoyage.com/tutoring" 
                        target="_blank" 
                        style={{ color: "#FFCD00" }}
                        >this information form</a>.
            <br/><br/>
            <b>Minimum time requirement:</b> 20 minutes per day, 5 days a week
          </Typography>
        </Box>
      </Box>

      <Box className={mobile} pt={8} pb={6}>
        <Box pb={2}>
          <Typography variant="h4" color={palette.common.white}>
            Personalized Online Tutoring
          </Typography>
        </Box>
        <Typography
          variant="body3"
          component={(props) => <p {...props} />}
          color={palette.common.white}
        >
          Word Voyage Online Tutoring is the perfect solution for students
          needing a structured program with regular feedback from a teacher. Our
          experienced tutors provide one-on-one, <b>hands-on instruction</b> in
          vocabulary and grammar. Lessons are tailored to meet the student's
          level. Regular feedback and face-to-face sessions are provided to keep
          the student on track and moving forward with skills.
          <br/><br/>
            For a free consultation on your child's specific needs, please  
            complete <a href="https://go.wordvoyage.com/tutoring" 
                        target="_blank" 
                        style={{ color: "#FFCD00" }}
                        >this information form</a>.
            <br/><br/>
            <b>Minimum time requirement:</b> 20 minutes per day, 5 days a week
        </Typography>
      </Box>
    </>
  );
};
