import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";

const customTheme = createTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#2B4EFF",
      light: "#F4F6FF",
      dark: "#D2D7E7",
    },
    common: {
      black: "#303030",
      white: "#FFFFFF",
    },
    grey: {
      main: "#808080",
    },
    secondary: {
      main: "#FCAA23",
    },
    info: {
      main: "#BB2BFF",
      light: "#2BB3FF",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "44px",
      fontWeight: "600",
      lineHeight: "56px",
    },
    h2: {
      fontSize: "36px",
      fontWeight: "600",
      lineHeight: "48px",
    },
    h3: {
      fontSize: "28px",
      fontWeight: "600",
      lineHeight: "42px",
    },
    h4: {
      fontSize: "24px",
      fontWeight: "600",
      lineHeight: "32px",
    },
    h5: {
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "36px",
    },
    body1: {
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "32px",
    },
    body2: {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "24px",
    },
    body3: {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    body4: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
    },
    body5: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "24px",
    },
    button: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "21px",
    },
    button1: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
    },
  },
});

export const ThemeProvider = ({ children }) => {
  return (
    <MuiThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
