import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { FooterDesktop } from "./footer-desktop";
import { FooterMobile } from "./footer-mobile";

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const Footer = () => {
  const { desktop, mobile } = useStyles();
  return (
    <Box bgcolor="#34566A" width="100%">
      <Box className={desktop}>
        <FooterDesktop />
      </Box>
      <Box className={mobile}>
        <FooterMobile />
      </Box>
    </Box>
  );
};
