import * as React from "react";

export const StarIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.157 7.412a.918.918 0 0 0-.796-.62l-5.23-.415-2.262-5.01a.916.916 0 0 0-1.671 0l-2.263 5.01-5.23.415a.917.917 0 0 0-.567 1.571l3.864 3.768-1.366 5.918a.917.917 0 0 0 1.403.97l4.994-3.33 4.995 3.33a.919.919 0 0 0 1.391-1.015l-1.678-5.87 4.16-3.745a.919.919 0 0 0 .256-.977Z"
      fill="#FFCD00"
    />
  </svg>
);
