import { useTheme } from "@emotion/react";
import { Box, Button, Typography, Container } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import HeaderImage from "../icons/headerBackgroundImage.png";
import { Navbar } from "./navbar";
import LandingPageMobileImage from "../../assets/landing-page-intro.png";
// import Animation from "https://d2qpy6phja3mya.cloudfront.net/static/media/animation.4633d6f27a62b7dc139f.webm";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#34566A",
    backgroundSize: "cover",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#34566A",
    },
  },
  headerContainerMobile: {
    background: `url(${HeaderImage}) no-repeat center center fixed`,
    backgroundSize: "cover",
    height: "125vh",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#34566A",
    },
  },
  centerVertical: {
    margin: 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(75%)",
  },
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    position: "relative",
    height: "125vh",
  },
}));

export const LandingPageHeader = () => {
  const { palette } = useTheme();
  const styles = useStyles();
  return (
    <>
      <Box width="100%" className={[styles.headerContainer, styles.desktop]}>
        <Container maxWidth="lg">
          <Box position="relative" zIndex={1}>
            <Navbar />
          </Box>
          <Box px={12} position="relative">
            <Box position="absolute" zIndex={0} top={-250} left={-250}>
              <Box
                height="778px"
                width="778px"
                borderRadius="50%"
                border="150px solid #FFFFFF"
                style={{ opacity: 0.05 }}
              />
            </Box>
            <Box height="25vh" />
            <Box>
              <Box maxWidth="559px">
                <Typography variant="h1" color={palette.common.white}>
                  We Teach the Roots that Power Vocabulary
                </Typography>
              </Box>
              <Box maxWidth="445px" pt={2}>
                <Typography
                  component="p"
                  variant="body3"
                  color={palette.common.white}
                >
                  How can we help students build large vocabularies?
                  <Typography
                    component="span"
                    variant="body3"
                    color={palette.secondary.main}
                    style={{ paddingRight: "4px" }}
                  >
                    <br/>
                    Teach them to fish!
                  </Typography>
                  Our students master the roots that drive thousands of words.
                </Typography>
              </Box>
              <Box pt={5}>
                <a
                  href="https://go.wordvoyage.com/select-trial"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Button
                  variant="contained"
                  style={{
                    borderRadius: "32px",
                    backgroundColor: "#FFCD00",
                    color: "#34566A",
                    paddingRight: "42px",
                    paddingLeft: "42px",
                    padding: "14px 42px 14px 42px",
                  }}
                >
                  <Typography
                    variant="button"
                    style={{ textTransform: "none", color: "#303030" }}
                  >
                    Free Trial
                  </Typography>
                </Button>
                </a>
              </Box>
            </Box>
          </Box>
          <Box
            position="absolute"
            bottom={0}
            right={"5%"}
            width="40vw"
            display="flex"
          >
	   <video width="100%" height="100%" autoPlay loop muted>
              <source backgroundColor="transparent" src="https://d2qpy6phja3mya.cloudfront.net/static/media/animation.4633d6f27a62b7dc139f.webm" type="video/webm" />
            </video>
          </Box>
        </Container>
      </Box>
      <Box className={styles.mobile}>
        <Box width="100%" className={styles.headerContainerMobile}>
          <Container maxWidth="lg">
            <Box position="relative" zIndex={1}>
              <Navbar />
            </Box>
            <Box mt={5} px={1}>
              <Typography
                variant="h2"
                color={palette.common.white}
                align="center"
              >
                We Teach the Roots that Power Vocabulary
              </Typography>
            </Box>
            <Box pt={2} textAlign="center">
              <Typography
                component="span"
                variant="body4"
                color={palette.common.white}
                style={{ paddingRight: "4px" }}
              >
                How can we help students build large vocabularies?
              </Typography>
              <Typography
                variant="body4"
                color={palette.secondary.main}
                component="span"
                style={{ paddingRight: "4px" }}
              >
                Teach them to fish!
              </Typography>
              <Typography
                variant="body4"
                component="span"
                color={palette.common.white}
              >
                Our students master the roots that drive thousands of words.
              </Typography>
            </Box>
            <Box pt={4} pb={1} display="flex" justifyContent="center">
              <Button
                variant="contained"
                style={{
                  borderRadius: "32px",
                  backgroundColor: "#FFCD00",
                  color: "#34566A",
                  paddingRight: "42px",
                  paddingLeft: "42px",
                  padding: "14px 42px 14px 42px",
                }}
                onClick={() => window.open("https://go.wordvoyage.com/select-trial", "_blank")}
              >
                <Typography variant="button" style={{ textTransform: "none" }}>
                  Free Trial
                </Typography>
              </Button>
            </Box>
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
	   <video width="100%" height="100%" autoPlay loop muted>
                <source src="https://d2qpy6phja3mya.cloudfront.net/static/media/animation.4633d6f27a62b7dc139f.webm" type="video/webm" />
              </video> 
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};
