import { Box, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useScrollPercentage } from "react-scroll-percentage";
import { Footer } from "../components/footer";
import { Navbar } from "../components/header";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  main: {
    width: "100vw",
    backgroundColor: "#34566A",
  },
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
export const PrivacyPolicyPage = () => {
  const { palette } = useTheme();
  const { main, desktop, mobile } = useStyles();
  const [ref, percentage] = useScrollPercentage();
  return (
    <>
      <Box className={desktop}>
        <Box className={main}>
          <Container maxWidth="lg">
            <Navbar />
            {/* <TutoringIntroduction /> */}
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box ref={ref} px={12} pt={5} pb={10}>
            <Grid container>
              <Grid item xs={12}>
              <Typography
                variant="h3"
                style={{ textAlign: "center"}}
              >
                Word Voyage Privacy Policy
              </Typography>
              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "10px" : "10px" }}
              >
                Word Voyage Educational Curriculum
              </Typography>
              <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: "16px" }}
      >
        Word Voyage is published by Adelja Learning Inc., and you are free to visit wordvoyage.com without identifying yourself. Privacy is important to Adelja Learning Inc, and the teachers, students and educational institutions that we serve. Adelja Learning Inc. is committed to following both legal obligations and best practices with regard to the collection, protection, use, and storage of private information. We also support the privacy policies of your educational institution.
<br/>
<br/>
Please read this Privacy Policy carefully because it describes our privacy practices and helps distinguish our service from others which have different policies.
<br/>
<br/>

California Privacy Rights Effective January 1, 2005, California Civil Code Section 1798.83 permits our customers who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please write to us at: Adelja Learning Inc, Attention: California Privacy Rights Request, 1143 Martin Luther King Jr Way, Box 22, Seattle, WA 98122
</Typography>
<Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                COPPA
              </Typography>
      <Typography variant="body1" color={palette.grey.main}>
      Our site complies with the Children's Online Privacy Protection Act "COPPA." For more information, please see:  <a href="https://www.ftc.gov/legal-library/browse/rules/childrens-online-privacy-protection-rule-coppa" 
                        target="_blank" 
                        style={{ color: "#FFCD00" }}
                        >this link</a>.
      </Typography>
      <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                Teacher and Student Accounts and Registration
              </Typography>
      <Typography variant="body1" color={palette.grey.main}>
      Word Voyage's unique online vocabulary curriculum provides an educational sequence personalized for each teacher and student. This requires being able to identify each school/school district. Each teacher and student within that unique school must register an account with Word Voyage. Adelja Learning Inc. assigns each school a unique ID. Teachers register their accounts first, associated with their school's ID. Students then register for an account by specifying their school ID and their teacher, and then enter their first name, last name, email address (not required), a username and a password. Student email addresses are used for the purpose of lesson reminders only. This feature must be authorized and activated by the teacher, and can by de-activated by the student or teacher at any time.
<br />
<br />
Identifying information is never shared with any outside parties and is securely stored on the Word Voyage web servers. Adelja Learning Inc. will never seek to directly contact your children. Adelja Learning Inc. will never market to children.

     </Typography>

              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >Security Measures

              </Typography>
              <Typography variant="body1" color={palette.grey.main}>
              Adelja Learning Inc. has taken reasonable steps to ensure that all information received from our online visitors (students and teachers) remains as secure as possible against unauthorized access and use. All personal information provided by customers is kept in the Adelja Learning Inc. secure databases, and is protected by our security measures, which are periodically reviewed. Bear in mind, however, that no data transmission over the Internet is 100% secure and any information disclosed online can potentially be collected and used by parties other than the intended recipient.
              </Typography>

              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                Cookies
              </Typography>
              <Typography variant="body1" color={palette.grey.main}>
              Due to Word Voyage's personalized lesson approach for each student, we receive and store certain types of information whenever the user interacts with the software. We use "cookies" to store this information. The use of cookies is standard across the Internet industry. They are used on most commercial and many non-commercial websites. Cookies are small text files written to your computer. In the case of Word Voyage, we use cookies to remember student answers to test questions, lessons completed, vocabulary lists assigned, words completed within each vocabulary list, etc.
              </Typography>

              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                Children's Privacy Guidelines
              </Typography>
              <Typography variant="body1" color={palette.grey.main}>
              Adelja Learning Inc. exists to serve and provide a safe, positive learning experience for children. We do not collect online contact information directly from children. Children are welcome to request support through our help services, however, our return communications will be strictly limited to responding directly to the child's question(s) and will not be used for other purposes. We do not distribute to third parties any personally identifiable information. We do not give the ability to publicly post or otherwise distribute personally identifiable contact information. We do not entice by the prospect of a special game, prize or other activity.
              </Typography>

      
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Footer />
      </Box>

      <Box className={mobile}>
        <Box className={main}>
          <Container maxWidth="lg">
            <Navbar />
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box pt={5} pb={10}>
          <Typography
                variant="h3"
                style={{ textAlign: "center"}}
              >
                Word Voyage Privacy Policy
              </Typography>
              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "10px" : "10px" }}
              >
                Word Voyage Educational Curriculum
              </Typography>
              <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: "16px" }}
      >
        Word Voyage is published by Adelja Learning Inc., and you are free to visit wordvoyage.com without identifying yourself. Privacy is important to Adelja Learning Inc, and the teachers, students and educational institutions that we serve. Adelja Learning Inc. is committed to following both legal obligations and best practices with regard to the collection, protection, use, and storage of private information. We also support the privacy policies of your educational institution.
<br/>
<br/>
Please read this Privacy Policy carefully because it describes our privacy practices and helps distinguish our service from others which have different policies.
<br/>
<br/>

California Privacy Rights Effective January 1, 2005, California Civil Code Section 1798.83 permits our customers who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please write to us at: Adelja Learning Inc, Attention: California Privacy Rights Request, 1143 Martin Luther King Jr Way, Box 22, Seattle, WA 98122
</Typography>
<Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                COPPA
              </Typography>
      <Typography variant="body1" color={palette.grey.main}>
      Our site complies with the Children's Online Privacy Protection Act "COPPA." For more information, please see:  <a href="https://www.ftc.gov/legal-library/browse/rules/childrens-online-privacy-protection-rule-coppa" 
                        target="_blank" 
                        style={{ color: "#FFCD00" }}
                        >this link</a>.
      </Typography>
      <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                Teacher and Student Accounts and Registration
              </Typography>
      <Typography variant="body1" color={palette.grey.main}>
      Word Voyage's unique online vocabulary curriculum provides an educational sequence personalized for each teacher and student. This requires being able to identify each school/school district. Each teacher and student within that unique school must register an account with Word Voyage. Adelja Learning Inc. assigns each school a unique ID. Teachers register their accounts first, associated with their school's ID. Students then register for an account by specifying their school ID and their teacher, and then enter their first name, last name, email address (not required), a username and a password. Student email addresses are used for the purpose of lesson reminders only. This feature must be authorized and activated by the teacher, and can by de-activated by the student or teacher at any time.
<br />
<br />
Identifying information is never shared with any outside parties and is securely stored on the Word Voyage web servers. Adelja Learning Inc. will never seek to directly contact your children. Adelja Learning Inc. will never market to children.

     </Typography>

              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >Security Measures

              </Typography>
              <Typography variant="body1" color={palette.grey.main}>
              Adelja Learning Inc. has taken reasonable steps to ensure that all information received from our online visitors (students and teachers) remains as secure as possible against unauthorized access and use. All personal information provided by customers is kept in the Adelja Learning Inc. secure databases, and is protected by our security measures, which are periodically reviewed. Bear in mind, however, that no data transmission over the Internet is 100% secure and any information disclosed online can potentially be collected and used by parties other than the intended recipient.
              </Typography>

              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                Cookies
              </Typography>
              <Typography variant="body1" color={palette.grey.main}>
              Due to Word Voyage's personalized lesson approach for each student, we receive and store certain types of information whenever the user interacts with the software. We use "cookies" to store this information. The use of cookies is standard across the Internet industry. They are used on most commercial and many non-commercial websites. Cookies are small text files written to your computer. In the case of Word Voyage, we use cookies to remember student answers to test questions, lessons completed, vocabulary lists assigned, words completed within each vocabulary list, etc.
              </Typography>

              <Typography
                variant="h5"
                style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
              >
                Children's Privacy Guidelines
              </Typography>
              <Typography variant="body1" color={palette.grey.main}>
              Adelja Learning Inc. exists to serve and provide a safe, positive learning experience for children. We do not collect online contact information directly from children. Children are welcome to request support through our help services, however, our return communications will be strictly limited to responding directly to the child's question(s) and will not be used for other purposes. We do not distribute to third parties any personally identifiable information. We do not give the ability to publicly post or otherwise distribute personally identifiable contact information. We do not entice by the prospect of a special game, prize or other activity.
              </Typography>
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
};
