import { useTheme } from '@emotion/react';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import logo from '../../assets/wv.svg';
import { Link } from 'react-router-dom';

export const FooterDesktop = () => {
  const { palette } = useTheme();

  return (
    <Box position='relative'>
      <Container maxWidth='lg'>
        <Box px={12} pt={5}>
          <Box
            width='100%'
            display='flex'
            alignItems='center'
            pb={10}
            borderBottom='1px solid #808080 '
          >
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <Box>
                  <Typography
                    fontSize='25px'
                    fontWeight='bold'
                    color={palette.common.white}
                  >
                    <img style={{ width: '230px' }} src={logo} />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h4' color={palette.common.white}>
                  Contact
                </Typography>
                <Box pt={3} display='flex' flexDirection='column'>
                  <Typography
                    variant='body3'
                    color={palette.common.white}
                    style={{ marginBottom: '11px' }}
                  >
                    Email: info@wordvoyage.com
                  </Typography>
                  <Typography variant='body3' color={palette.common.white}>
                    Phone: +1 (603) 856-0072
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='h4' color={palette.common.white}>
                  Mailing Address
                </Typography>
                <Box pt={3} display='flex' flexDirection='column'>
                  <Typography
                    variant='body3'
                    color={palette.common.white}
                    style={{ marginBottom: '11px' }}
                  >
                    1143 Martin Luther King Jr Way, Box 22
                  </Typography>
                  <Typography variant='body3' color={palette.common.white}>
                    Seattle, WA 98122 (United States)
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            width='100%'
            display='flex'
            pt={2}
            pb={4}
            opacity='0.5'
            justifyContent='flex-end'
          >
            <Typography variant='body3' color='rgba(255, 255, 255, 0.502972)'>
              <a href='/privacy-policy'>
                {' '}
                <Typography
                  variant='body3'
                  color={palette.common.white}
                  style={{ paddingRight: '5px' }}
                >
                  Privacy Policy
                </Typography>
              </a>
              © {new Date().getFullYear()} Adelja Learning, Inc.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
