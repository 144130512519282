import { useTheme } from "@emotion/react";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import {
  BuildYourVocabularyLogo,
  ImproveReadingLogo,
  RaiseTestScoresLogo,
  WordRootsLogo,
} from "../../icons";

export const MethodologyDesktop = () => {
  const { palette } = useTheme();
  return (
    <Box position="relative" mt={5}>
      <Container maxWidth="lg">
        <Box px={12} pt={1}>
          <Box width="100%" display="flex" justifyContent="center">
            <Typography variant="h1" color={palette.common.black}>
              Our Method
            </Typography>
          </Box>
          <Box width="100%" display="flex" justifyContent="center" pt={2}>
          <Box >
              <Box
                bgcolor={palette.secondary.main}
                border={`4px solid ${palette.secondary.main}`}
                width="90px"
                borderRadius="4px"
              />
            </Box>
          </Box>
          <Box pt={10} pb={1} mb={5}>
            <Grid container columnSpacing={6} justifyContent="center">
              <Grid item xs={3}>
                <WordRootsLogo />
                <Box pt={6} width="60%">
                  <Typography variant="h4" color="#272D4E" textAlign="center">
                    Learn Word Roots
                  </Typography>
                </Box>
                <Box pt={2} width="80%">
                  <Typography
                    component="p"
                    variant="body4"
                    color="#737B7D"
                    textAlign="start"
                  >
                    Over 90% of English words with 3 or more syllables have
                    Latin or Greek roots. These are often the high-priority
                    vocabulary in any text.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <BuildYourVocabularyLogo />
                <Box pt={8.5} width="80%">
                  <Typography variant="h4" color="#272D4E" textAlign="center">
                    Build Vocabulary Exponentially
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    component="div"
                    textAlign="start"
                    variant="body4"
                    color="#737B7D"
                  >
                    With regular practice, our students learn to spot the
                    root-meaning of words. English has a built-in clue system!
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <ImproveReadingLogo />
                <Box pt={6} width="80%">
                  <Typography variant="h4" color="#272D4E" textAlign="center">
                    Improve Reading Comprehension
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    component="div"
                    textAlign="start"
                    variant="body4"
                    color="#737B7D"
                  >
                    Slow down, break it down! Word Voyage students learn to
                    engage unfamiliar words rather than skip over them.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <RaiseTestScoresLogo />
                <Box pt={6} width="70%">
                  <Typography variant="h4" color="#272D4E" textAlign="center">
                    Raise Test Scores
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    variant="body4"
                    color="#737B7D"
                    component="div"
                    textAlign="start"
                  >
                    Root words make up the vast majority of the vocabulary on
                    standardized tests. Learn roots, raise scores!
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
