import { useTheme } from "@emotion/react";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircleIcon } from "../../icons/check-circle-icon";

export const ServicesMobile = () => {
  const { palette } = useTheme();
  return (
    <Box minHeight="100vh" position="relative">
      <Container maxWidth="sm">
        <Box pt={6}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box>
              <Typography variant="h4" color={palette.common.black}>
                What we offer
              </Typography>
            </Box>
            <Box pt={2} pb={3}>
              <Box
                bgcolor={palette.secondary.main}
                border={`4px solid ${palette.secondary.main}`}
                width="90px"
                borderRadius="4px"
              />
            </Box>
            <Box px={2}>
              <Typography
                component="p"
                textAlign="center"
                variant="body4"
                color={palette.grey.main}
              >
                We are committed to teaching students the skills they need to
                become lifelong learners.
              </Typography>
            </Box>
            <Box pt={6} pb={6} px={2}>
              <Box pb={7}>
                <Paper style={{ borderRadius: "8px" }} elevation={5}>
                  <Box p={4}>
                    <Box>
                      <Typography variant="h4">Tutoring Solutions</Typography>
                    </Box>
                    <Box pt="20px">
                      <Typography component="p" variant="body4" color="#808080">
                        Our experienced tutors offer intensive instruction in English fundamentals. 
                        Customized lessons feature words from the student’s current reading assignments. 
                        Regular comments and one-on-one meetings ensure rapid progress.
                      </Typography>
                    </Box>
                    <Box pt={4}>
                      <Box display="flex" alignItems="center">
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Customized Instruction
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Latin & Greek Roots
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Academic Vocabulary
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Grammar & Sentence Construction
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Regular Feedback
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Face-to-Face Sessions
                        </Typography>
                      </Box>
                    </Box>
                    <Box pt={6}>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          borderRadius: "32px",
                          backgroundColor: "#34566A",
                          color: "white",
                          paddingRight: "42px",
                          paddingLeft: "42px",
                          padding: "14px 42px 14px 42px",
                        }}
                        onClick={() => window.open("/tutoring", "_self")}
                      >
                        <Typography
                          // variant="button"
                          variant="body3" color={palette.common.white}
                          style={{ textTransform: "none" }}
                        >
                          Learn More
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
              <Box>
                <Paper style={{ borderRadius: "8px" }} elevation={5}>
                  <Box p={4}>
                    <Box>
                      <Typography variant="h4">School Solutions</Typography>
                    </Box>
                    <Box pt="20px">
                      <Typography component="p" variant="body4" color="#808080">
                        Word Voyage provides an integrated Word Roots,
                        Vocabulary, and Grammar program for grades 4 and up.
                        Instruction can be customized to match your curriculum.
                        We provide word lists for over 1300 literature titles,
                        plus the service to add additional lists on request.
                      </Typography>
                    </Box>
                    <Box pt={4}>
                      <Box display="flex" alignItems="center">
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Vocabulary & Grammar in One Program
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Word Lists from any Literature Title
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Word Roots & Root Families
                        </Typography>
                      </Box>

                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Sentence-Writing Platform
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" pt={2}>
                        <CheckCircleIcon />
                        <Typography
                          variant="body5"
                          color="#34566A"
                          style={{ paddingLeft: "16px" }}
                        >
                          Streamlined Comment System
                        </Typography>
                      </Box>
                    </Box>
                    <Box pt={6}>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          borderRadius: "32px",
                          backgroundColor: "#34566A",
                          color: "white",
                          paddingRight: "42px",
                          paddingLeft: "42px",
                          padding: "14px 42px 14px 42px",
                        }}
                        onClick={() => window.open("/school", "_self")}
                      >
                        <Typography
                          // variant="button"
                          variant="body3" color={palette.common.white}
                          style={{ textTransform: "none" }}
                        >
                          Learn more
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
