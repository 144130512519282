import { Box, Button, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React, { forwardRef, useRef } from "react";

const useStyles = makeStyles({
  stickyContainer: {
    position: "sticky",
    top: 50,
  },
  tabs: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    borderLeft: "2px solid #CBD0D3",
    paddingLeft: "26px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
});

const Tab = forwardRef(({ isOnElement, label, removeMargin }, ref) => {
  const { palette } = useTheme();
  return (
    <Box position="relative" mb={removeMargin ? "0px" : "40px"}>
      <Box
        bgcolor={isOnElement ? "#34566A" : "transparent"}
        style={{
          borderRadius: "4px",
          height: ref.current?.clientHeight + 25,
          width: "4px",
          position: "absolute",
          left: -29.5,
          top: -12,
        }}
      />
      <Box ref={ref}>
        <Typography
          variant="body2"
          color={isOnElement ? "#34566A" : palette.grey.main}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
});

export const SchoolBodyGradeFourToFiveTabs = ({ percentage }) => {
  const { tabs, stickyContainer } = useStyles();
  const containerRef = useRef(null);
  const literatureBasedVocabRef = useRef(null);
  const latinAndGreekRootsRef = useRef(null);
  const wordMasteryFromExercisesRef = useRef(null);
  const grammerAndSentenceConstructionRef = useRef(null);
  const oneOnOneGuidanceRef = useRef(null);

  const isOnLiteratureBasedVocab = percentage < 0.35;
  const isOnLatinAndGreekRoots = (percentage > 0.35) & (percentage < 0.55);
  const isOnWorkMasteryFromExercises =
    (percentage > 0.55) & (percentage < 0.65);
  const isOnGrammerAndSentence = (percentage > 0.65) & (percentage < 0.69);
  const isOnOneOnOneGuidance = percentage > 0.69;

  return (
    <Box ref={containerRef} className={stickyContainer}>
      <Box className={tabs}>
        <Tab
          label="Literature-Based Vocabulary"
          ref={literatureBasedVocabRef}
          isOnElement={isOnLiteratureBasedVocab}
        />

        <Tab
          label="Latin and Greek roots"
          ref={latinAndGreekRootsRef}
          isOnElement={isOnLatinAndGreekRoots}
        />

        <Tab
          label="Excercises and Games"
          ref={wordMasteryFromExercisesRef}
          isOnElement={isOnWorkMasteryFromExercises}
        />

        <Tab
          label="Grammar and Sentence Construction"
          ref={grammerAndSentenceConstructionRef}
          isOnElement={isOnGrammerAndSentence}
        />

        <Tab
          removeMargin
          label="One on One Guidance"
          ref={oneOnOneGuidanceRef}
          isOnElement={isOnOneOnOneGuidance}
        />
      </Box>

      <Box pt={6}>
        <Button
          variant="contained"
          fullWidth
          style={{
            borderRadius: "32px",
            backgroundColor: "#34566A",
            color: "white",
            paddingRight: "42px",
            paddingLeft: "42px",
            padding: "14px 42px 14px 42px",
          }}
          onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSeqS4_hIhsYqfpmGUjCCe9fRRgsJM-80_Yp1CRLzqTWs1R-TA/viewform?c=0&w=1", "_blank")}
        >
          Get a quote
        </Button>
      </Box>
    </Box>
  );
};
