import { Box, Button, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React, { forwardRef, useRef } from "react";

const useStyles = makeStyles({
  stickyContainer: {
    position: "sticky",
    top: 50,
  },
  tabs: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    borderLeft: "2px solid #CBD0D3",
    paddingLeft: "26px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
});

const Tab = forwardRef(({ isOnElement, label, removeMargin }, ref) => {
  const { palette } = useTheme();
  return (
    <Box position="relative" mb={removeMargin ? "0px" : "40px"}>
      <Box
        bgcolor={isOnElement ? "#34566A" : "transparent"}
        style={{
          borderRadius: "4px",
          height: ref.current?.clientHeight + 25,
          width: "4px",
          position: "absolute",
          left: -29.5,
          top: -12,
        }}
      />
      <Box ref={ref}>
        <Typography
          variant="body2"
          color={isOnElement ? "#34566A" : palette.grey.main}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
});

export const SchoolBodyGradeNineToTwelveTabs = ({ percentage }) => {
  const { tabs, stickyContainer } = useStyles();

  const targetingSkillGapRef = useRef(null);
  const satAndActPrepRef = useRef(null);
  const literatureBasedVocabRef = useRef(null);
  const nothingLeftToChanceRef = useRef(null);

  const isOnTargetingSkillGap = (percentage > 0.2) & (percentage < 0.35);
  const isOnSatAndAct = (percentage > 0.35) & (percentage < 0.55);
  const isOnLitBasedVocab = (percentage > 0.55) & (percentage < 0.65);
  const isOnNothingLeftToChance = (percentage > 0.65) & (percentage < 0.69);

  return (
    <Box className={stickyContainer}>
      <Box className={tabs}>
        <Tab
          label="Targeting the Skill Gap"
          ref={targetingSkillGapRef}
          isOnElement={isOnTargetingSkillGap}
        />
        <Tab
          label="SAT and ACT Preparation"
          ref={satAndActPrepRef}
          isOnElement={isOnSatAndAct}
        />
        <Tab
          label="Literature Based Vocabulary"
          ref={literatureBasedVocabRef}
          isOnElement={isOnLitBasedVocab}
        />
        <Tab
          removeMargin
          label="Nothing Left to Chance"
          ref={nothingLeftToChanceRef}
          isOnElement={isOnNothingLeftToChance}
        />
      </Box>

      <Box pt={6}>
        <Button
          variant="contained"
          fullWidth
          style={{
            borderRadius: "32px",
            backgroundColor: "#34566A",
            color: "white",
            paddingRight: "42px",
            paddingLeft: "42px",
            padding: "14px 42px 14px 42px",
          }}
          onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSeqS4_hIhsYqfpmGUjCCe9fRRgsJM-80_Yp1CRLzqTWs1R-TA/viewform?c=0&w=1", "_blank")}
        >
          Get a quote
        </Button>
      </Box>
    </Box>
  );
};
