import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Paper, Typography, Container } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircleIcon } from "../../icons/check-circle-icon";
import { LandingPageServicesCombinedShape } from "../../icons/landing-page-services-combined-shape";

export const ServicesDesktop = () => {
  const [menu, setMenu] = useState(false);
  const { palette } = useTheme();
  const closeMenu = () => setMenu(false);
  return (
    <Box minHeight="100vh" position="relative" pt={5} pb={0}>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={3} align="center">
          <Grid item>
            <Box width="100%" display="flex" justifyContent="center">
              <Typography variant="h1" color={palette.common.black}>
                What We Offer
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box width="100%" display="flex" justifyContent="center">
            <Box >
              <Box
                bgcolor={palette.secondary.main}
                border={`4px solid ${palette.secondary.main}`}
                width="90px"
                borderRadius="4px"
              />
            </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box width="45%" display="flex" justifyContent="center">
              <Typography variant="body3">
                We are committed to teaching students the skills they need to
                become lifelong learners.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box pt={10} display="flex" justifyContent="space-evenly" height="100%">
          <Box position="relative">
            <Paper style={{ borderRadius: "8px" }} elevation={5}>
              <Box width="432px" px={6} py={6}>
                <Box>
                  <Typography variant="h4">Tutoring Solutions</Typography>
                </Box>
                <Box pt="20px">
                  <Typography variant="body4">
                    Our experienced tutors offer intensive instruction in English fundamentals. 
                    Customized lessons feature words from the student’s current reading assignments. 
                    Regular comments and one-on-one meetings ensure rapid progress.
                  </Typography>
                </Box>
                <Box pt={4}>
                  <Box display="flex" alignItems="center">
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Customized Instruction
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Latin & Greek Roots
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Academic Vocabulary
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Grammar & Sentence Construction
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Regular Feedback
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Face-to-Face Sessions
                    </Typography>
                  </Box>
                </Box>
                <Box pt={6} pb={3}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      borderRadius: "32px",
                      backgroundColor: "#34566A",
                      color: "white",
                      paddingRight: "42px",
                      paddingLeft: "42px",
                      padding: "14px 42px 14px 42px",
                    }}
                    onClick={() => window.open("/tutoring", "_self")}
                  >
                  <Typography variant="body3" color={palette.common.white}>
                    Learn More
                  </Typography>
                  </Button>
                </Box>
              </Box>
            </Paper>
            <Box position="absolute" top={-30} left={-60} zIndex={-1}>
              <LandingPageServicesCombinedShape />
            </Box>
            <Box position="absolute" top={600} left={-150} zIndex={-1}>
              <Box
                height="18px"
                width="18px"
                borderRadius="50%"
                bgcolor="#FFCD00"
              />
            </Box>
            <Box position="absolute" top={600} right={-18} zIndex={1}>
              <Box
                height="35px"
                width="35px"
                borderRadius="50%"
                border="4px solid #FFCD00"
              />
            </Box>
          </Box>
          <Box position="relative">
            <Paper style={{ borderRadius: "8px" }} elevation={5}>
              <Box width="432px" px={6} py={6}>
                <Box>
                  <Typography variant="h4">School Solutions</Typography>
                </Box>
                <Box pt="20px">
                  <Typography variant="body4">
                    Word Voyage provides an integrated Word Roots, Vocabulary,
                    and Grammar program for grades 4 and up. Instruction can be
                    customized to match your curriculum. We provide word lists
                    for over 1300 literature titles, plus the service to add
                    additional lists on request.
                  </Typography>
                </Box>
                <Box pt={4}>
                  <Box display="flex" alignItems="center" position="relative">
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{
                        paddingLeft: "32px",
                        position: "absolute",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Vocabulary & Grammar in One Program
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Word Lists from any Literature Title
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Word Roots & Root Families
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Sentence-Writing Platform
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" pt={3}>
                    <CheckCircleIcon />
                    <Typography
                      variant="body2"
                      color="#34566A"
                      style={{ paddingLeft: "16px" }}
                    >
                      Streamlined Comment System
                    </Typography>
                  </Box>
                </Box>
                <Box pt={12} pb={3}>
              
                 <Button
                    variant="contained"
                    fullWidth
                    style={{
                      borderRadius: "32px",
                      backgroundColor: "#34566A",
                      color: "white",
                      paddingRight: "42px",
                      paddingLeft: "42px",
                      padding: "14px 42px 14px 42px",
                    }}
                    onClick={() => window.open("/school", "_self")}
                  >
                  <Typography variant="body3" color={palette.common.white}>
                    Learn More
                  </Typography>
                      
                  </Button>
                 
                </Box>
              </Box>
            </Paper>
            <Box position="absolute" top={400} right={-20} zIndex={1}>
              <Box
                height="41px"
                width="41px"
                borderRadius="50%"
                border="4px solid #FFCD00"
              />
            </Box>
            <Box position="absolute" bottom={-30} right={-60} zIndex={-1}>
              <LandingPageServicesCombinedShape />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
