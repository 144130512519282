import { Box } from "@mui/material";
import React from "react";
import {
  Footer,
  LandingPageHeader,
  Methodology,
  Services,
  Testimonial,
} from "../components";

export const LandingPage = () => {
  return (
    <Box minHeight="100vh">
      <LandingPageHeader />
      <Box>
        <Methodology />
        <Services />
        <Testimonial />
      </Box>
      <Box bgcolor="#34566A" width="100%">
        <Footer />
      </Box>
    </Box>
  );
};
