import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import GradeSixToEight from "../../assets/grade-six-to-eight.png";

export const SchoolBodyGradeSixToEightContent = () => {
  const { palette } = useTheme();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Typography variant="h3">MAKE VOCABULARY RELEVANT</Typography>

      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "56px" : "80px", paddingTop: "32px" }}
      >
        Our students study words sourced from their current reading assignments.
        Word Voyage includes hundreds of lists from classic and popular titles,
        plus a service to add additional lists on request–from any subject area!
        <span style={{fontWeight: "600", color: "black"}}> Word Voyage students study the vocabulary </span><span style={{fontWeight: "400", color: "#FFCD00", fontStyle: "italic"}}> they need now</span>!
      </Typography>
      <img
        width="100%"
        height="auto"
        src={GradeSixToEight}
        alt={"Literature-Based Vocabulary"}
        loading="lazy"
      />
      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        IMPROVES READING COMPREHENSION
      </Typography>
      <Typography variant="body1" color={palette.grey.main}>
        Students often skip over unfamiliar words, a habit that can undermine
        academic success and test performance. Word Voyage teaches a quick and
        reliable <span style={{fontWeight: "600", color: "black"}}>word-attack strategy</span> we call <span style={{fontWeight: "600", color: "black"}}>Slow Down-Break It Down</span>! It's all
        about Latin and Greek roots, the meaning components of most
        multisyllabic words in English. These are often the high-priority words
        that describe characters, scenes, and moods. With practice, and our
        students get LOTS of practice, the new habit of analyzing root patterns
        takes hold, meanings are revealed, and reading comprehension is greatly
        improved.
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        KEEP THE FOCUS ON WRITING
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        There are lots of ways to teach vocabulary and grammar, but when it
        comes to assessment, there is no substitute for evaluating the student's
        original writing. For this reason, our students write hundreds of
        sentences demonstrating word usage and grammar skills. The sentences are
        reviewed online by the teacher and returned for revision as needed.
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        MOVE GRAMMAR SKILLS FORWARD
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage includes dozens of preset grammar assignments supported by
        instructional videos. Assignments can be customized as needed. The
        students use formatting tools to identify specific sentence features and
        to structurally analyze their writing. <span style={{fontWeight: "600", color: "black"}}>Middle School is the time to <span style={{fontWeight: "400", color: "#FFCD00", fontStyle: "italic"}}>get
        the grammar game on</span>!</span>
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        PROVIDE REGULAR FORMATIVE FEEDBACK
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        It would be very helpful if computers could accurately evaluate grammar
        and word usage, but they are not there yet and may never be. Our
        solution is to provide a highly-efficient platform for teachers to
        deliver formative feedback. Frequently used comments are saved for
        redeployment with 1 click. This system provides the regular guidance
        students need to build a solid foundation of skills, and to learn that
        yes, <span style={{fontWeight: "400", color: "#FFCD00", fontStyle: "italic"}}>details do matter</span>!
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        DIFFERENTIATE INSTRUCTION
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Since the skill levels of Middle School students can be all over the
        map, one-size-fits-all teaching tools are of limited value. Word Voyage
        builds a diverse data set for each student that the teacher can use to
        tailor instruction. No matter where students are with their skills, Word
        Voyage can deliver the instruction they need to move forward.
      </Typography>
      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        MANAGE LARGE STUDENT LOADS
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Using our lesson management tools, one teacher can manage individualized
        lessons for over 100 students in <span style={{fontWeight: "600", color: "black"}}>15-20 minutes per day</span>. Word Voyage
        provides the automation and leverage teachers need to efficiently serve
        each student.
      </Typography>
    </>
  );
};
