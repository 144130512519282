import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { ServicesDesktop } from "./service-desktop";
import { ServicesMobile } from "./service-mobile";
const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const Services = () => {
  const { desktop, mobile } = useStyles();
  return (
    <>
      <Box className={desktop}>
        <ServicesDesktop />
      </Box>
      <Box className={mobile}>
        <ServicesMobile />
      </Box>
    </>
  );
};
