import * as React from "react";

export const LandingPageServicesCombinedShape = (props) => (
  <svg
    width={188}
    height={52}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108 2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm16 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM122 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM2 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm16 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm10 30a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm16 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM26 36a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM18 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm90 30a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-2 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm16 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm-64-16a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM34 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm14 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm18-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      fill="#FFCD00"
    />
  </svg>
);
