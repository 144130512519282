import { Box, Button, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React, { forwardRef, useRef } from "react";

const useStyles = makeStyles({
  stickyContainer: {
    position: "sticky",
    top: 50,
  },
  tabs: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    borderLeft: "2px solid #CBD0D3",
    paddingLeft: "26px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
});

const Tab = forwardRef(({ isOnElement, label, removeMargin }, ref) => {
  const { palette } = useTheme();
  return (
    <Box position="relative" mb={removeMargin ? "0px" : "40px"}>
      <Box
        bgcolor={isOnElement ? "#34566A" : "transparent"}
        style={{
          borderRadius: "4px",
          height: (ref.current?.clientHeight || 40) + 25,
          width: "4px",
          position: "absolute",
          left: -29.5,
          top: -12,
        }}
      />
      <Box ref={ref}>
        <Typography
          variant="body2"
          color={isOnElement ? "#34566A" : palette.grey.main}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
});

export const TutoringTabs = ({ percentage }) => {
  const { tabs, stickyContainer } = useStyles();

  const vocabMethodRef = useRef(null);
  const grammarMethodRef = useRef(null);
  const gradeLevelDetailsRef = useRef(null);
  
  const isOnVocabMethod = percentage < 0.44;
  const isOnGrammarMethod = (percentage > 0.44) & (percentage < 0.56);
  const isOnGradeLevelDetails = percentage > 0.56;

  return (
    <Box className={stickyContainer}>
      <Box className={tabs}>
        <Tab
          label="Vocabulary Method"
          ref={vocabMethodRef}
          isOnElement={isOnVocabMethod}
        />
        <Tab
          label="Grammar Method"
          ref={grammarMethodRef}
          isOnElement={isOnGrammarMethod}
        />
        <Tab
          removeMargin
          label="Grade Level Details"
          ref={gradeLevelDetailsRef}
          isOnElement={isOnGradeLevelDetails}
        />
      </Box>

      <Box pt={6}>
        <Button
          variant="contained"
          fullWidth
          style={{
            borderRadius: "32px",
            backgroundColor: "#34566A",
            color: "white",
            paddingRight: "42px",
            paddingLeft: "42px",
            padding: "14px 42px 14px 42px",
          }}
          onClick={() => window.open("https://go.wordvoyage.com/tutoring", "_blank")}
        >
          Register Now
        </Button>
      </Box>
    </Box>
  );
};
