import * as React from "react";

export const RaiseTestScoresLogo = (props) => (
  <svg
    width={194}
    height={161}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.1}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M186.317 120.701a70.897 70.897 0 0 1-4.668 7.236c-13.019 17.744-34.857 30.43-58.241 23.314-47.693-14.513-52.512 15.06-83.842 8.734-11.75-2.372-21.329-12.352-28.117-25.717a81.971 81.971 0 0 1-2.747-5.989 81.521 81.521 0 0 1-4.836-16.569C-1.17 83.726 8.68 54.396 30.996 34.993L44.6 23.163a83.013 83.013 0 0 1 40.182-19.11c24.834-4.345 60.544-7.074 66.591 11.485 6.284 19.281 7.427 27.121 15.436 37.642a79.397 79.397 0 0 0 2.781 3.438 97.336 97.336 0 0 0 1.641 1.877c2.565 2.866 5.653 6.024 9.439 9.683l.636.613c.061.06.125.119.188.18l.22.212.596.57a502.7 502.7 0 0 0 3.199 3.02c11.952 11.188 10.408 30.842.807 47.928Z"
      fill="#34566A"
    />
    <path
      d="M90.283 76.165c-.483-.293-1.757-.893-3.716-1.146-.684-.093-.885-.266-.885-.56.027-1.171 3.313-.732 4.292.467 1.543-.36 2.777-2.624.43-4.168-1.154-.76-3.609-.986-5.38-.507-3.218.853-3.674 3.476-3.5 4.595.416 2.504 2.844 3.396 5.017 3.556 1.006.067 1.354.453 1.207.985-.215.813-1.985.786-3.877-.146-2.186-1.079-3.688 1.651-2.387 3.276 2.414 1.971 6.572 1.638 8.343.932 2.522-1.025 3.863-5.194.456-7.284Z"
      fill="#FFCD00"
    />
    <path
      d="M93.012 79.64c-.577 3.024 1.02 5.114 4.32 3.916.187-.693.402-1.358.63-1.918.858.027 1.797-.026 2.642.013 1.06 3.65 5.151 1.998 5.339.547-.51-2.797-1.905-7.485-2.629-9.243-1.53-3.702-5.527-2.863-7.364-2.264-.859 1.665-2.415 6.273-2.938 8.95Zm5.473-1.81c.255-1.093.51-2.132.75-3.184.041 0 .081 0 .122-.013.255 1.065.523 2.118.778 3.196h-1.65ZM115.519 70.371c-1.529.014-7.149-.066-8.839.04-2.549.16-2.737 3.276-1.878 4.382.872.173 1.851.16 2.75.093-.041 2.544.08 5.3.214 6.046.59 3.09 3.528 3.53 4.655 2.291.255-1.825.348-5.847.255-8.324.067 0 .402-.026.871-.053 3.005-.186 2.683-3.542 1.972-4.475Z"
      fill="#FFCD00"
    />
    <path
      d="M102.973 24.955c-.491-3.196-3.466-3.196-4.701-2.104-.425 1.625-.28 8.976-.053 10.161.584 2.997 3.493 3.436 4.608 2.224.306-2.237.385-8.683.146-10.281ZM112.774 28.165c-.478-.293-1.74-.893-3.679-1.146-.677-.093-.877-.266-.877-.56.027-1.171 3.281-.732 4.25.467 1.528-.36 2.749-2.624.425-4.168-1.142-.76-3.572-.986-5.325-.506-3.188.852-3.639 3.475-3.467 4.594.412 2.504 2.816 3.396 4.967 3.556.996.066 1.342.453 1.196.985-.213.813-1.966.786-3.839-.146-2.164-1.079-3.652 1.651-2.363 3.276 2.39 1.971 6.507 1.638 8.26.932 2.497-1.025 3.825-5.194.452-7.284ZM115.696 32.586c.106 1.811.65 2.703 1.899 3.023.956.227 4.422.2 5.525.053 2.523-.332 2.868-2.636 1.859-4.061h-4.569v-.813c1.235 0 1.634.027 2.311 0 1.952-.093 2.563-1.638 1.767-3.41-1.129-.359-3.321-.212-4.091-.212v-.693h2.736c3.201 0 2.855-3.303 2.364-4.008-2.364-.187-7.371-.24-9.589-.014-.265 2.25-.319 8.31-.212 10.135ZM126.882 32.586c.106 1.811.651 2.703 1.899 3.023.956.227 4.423.2 5.525.053 2.523-.332 2.868-2.636 1.859-4.061h-4.568v-.813c1.235 0 1.633.027 2.31 0 1.953-.093 2.564-1.638 1.767-3.41-1.129-.359-3.32-.212-4.091-.212v-.693h2.736c3.201 0 2.855-3.303 2.364-4.008-2.364-.187-7.371-.24-9.589-.014-.265 2.25-.318 8.31-.212 10.135Z"
      fill="#BB2BFF"
    />
    <path
      d="M34.505 45.253c-1.3.011-6.08-.058-7.516.034-2.167.138-2.327 2.824-1.597 3.777.741.149 1.574.138 2.338.08-.034 2.193.069 4.569.183 5.212.501 2.663 3 3.042 3.957 1.974.217-1.572.297-5.04.217-7.174.057 0 .342-.023.741-.046 2.555-.161 2.281-3.054 1.677-3.857Z"
      fill="#34566A"
    />
    <path
      d="M40.299 45c-3.137.012-4.654 1.733-5.372 3.352-.536 1.182-.627 2.881-.285 4.362.353 1.573 1.186 2.801 2.634 3.547 1.517.792 3.125.919 4.768.517 2.03-.482 3.364-1.745 3.98-3.742.456-1.516.33-3.008-.205-4.477-.343-.907-1.631-3.582-5.52-3.559Zm.057 4.11c.81 0 1.54.746 1.46 2.043-.069 1.297-.81 1.768-1.415 1.802-.593.046-1.471-.424-1.55-1.756-.092-1.366.66-2.09 1.505-2.09ZM47.4 54.057c.09 1.561.558 2.33 1.63 2.606.822.195 3.798.172 4.745.046 2.167-.287 2.464-2.273 1.597-3.501h-3.924v-.7c1.061 0 1.403.023 1.985 0 1.676-.08 2.201-1.412 1.517-2.94-.97-.31-2.852-.183-3.513-.183v-.597h2.35c2.748 0 2.452-2.847 2.03-3.455-2.03-.16-6.33-.207-8.235-.011-.228 1.94-.274 7.163-.182 8.735ZM57.04 54.574c.297 2.422 2.988 2.583 3.97 1.87.045-.47.045-2.49.045-2.743 1.06 0 1.403.035 1.984 0 1.677-.069 2.201-1.802 1.517-3.329-.97-.31-2.851-.183-3.513-.183v-1.366h2.35c2.748 0 2.452-2.836 2.03-3.444-2.03-.16-6.341-.207-8.235-.023-.228 1.94-.342 7.61-.148 9.218ZM73.71 56.72c1.596-.103 2.907-2.066 1.984-3.8-1.609-.194-3.684-.217-4.722-.137.046-1.435.046-3.822-.091-5.177-.308-2.813-2.989-2.79-4.038-1.837-.445 2.836-.285 6.842-.045 8.46.33 2.25 2.2 2.491 3.376 2.491 0 0 1.927.104 3.535 0Z"
      fill="#34566A"
    />
    <path
      d="M138.849 123.28c1.878.808 4.435-.701 3.727-3.598-3.077-1.875-5.98-1.875-8.378-.64-1.791.914-3.12 2.241-3.755 4.238-.564 1.784-.607 3.888-.029 5.626.679 2.089 2.34 4.895 7.049 4.895 5.431 0 6.63-4.01 6.514-5.977-.144-2.653-4.03-2.806-4.709-1.83-.029 1.174-.057 2.729-1.632 2.729-1.025.016-1.964-.747-2.109-2.134-.159-1.159.058-2.318 1.069-2.973.766-.519 1.387-.564 2.253-.336ZM155.75 127.915a1.54 1.54 0 0 0 .303-.198c3.279-2.546 1.892-6.892-.404-8.523-2.904-2.043-8.985-1.113-10.241.183-.477 1.845-.304 9.895-.058 11.252.621 3.446 3.799 3.934 4.998 2.546.101-.747.188-2.104.245-3.354.015-.32.029-.64.029-.915a.26.26 0 0 0 .101-.03c.072.107.159.229.231.351.881 1.463 1.3 2.134 1.661 2.744 1.705 2.927 5.128 2.516 5.59-.137-.317-.915-1.776-3.248-2.455-3.919Zm-3.005-3.903c0 .427-.202.717-.65.793-.346.076-.707.061-1.068.061-.058 0-.174-.122-.174-.183-.014-.534 0-.778 0-1.418 1.098-.534 1.892.015 1.892.747ZM158.995 130.065c.116 2.074.708 3.095 2.066 3.461 1.04.259 4.81.229 6.009.061 2.744-.381 3.12-3.019 2.022-4.65H164.123v-.93c1.343 0 1.777.03 2.513 0 2.124-.107 2.788-1.876 1.922-3.903-1.228-.412-3.612-.244-4.449-.244v-.793h2.975c3.481 0 3.106-3.781 2.571-4.59-2.571-.213-8.016-.274-10.429-.015-.288 2.577-.346 9.514-.231 11.603Z"
      fill="#3B61F0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m191.617 28.169-9.043 26.901-6.957-4.442-40.523 53.311H111.44L95.759 123.19H70.22l-17.179 20.852-26.914-.046-8.822 7.769a59.049 59.049 0 0 1-7.326-9.858l12.413-10.079h28.292l18.319-17.276H94.28l16.233-16.784h22.029l40.245-49.306-7.084-5.608 25.914-14.685ZM0 120.796l17.169-20.113 17.277 4.344 9.822-8.133-4.24-3.105 17.856-5.816-5.954 14.99-3.595-3.291L35.1 112.986l-15.874-3.641-6.63 11.521L0 120.796Z"
      fill="#FFCD00"
    />
  </svg>
);
