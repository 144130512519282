import * as React from "react";

export const RightChevronIcon = (props) => (
  <svg
    width={8}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.59 10.59 5.17 6 .59 1.41 2 0l6 6-6 6-1.41-1.41Z"
      fill="#FFCD00"
    />
  </svg>
);
