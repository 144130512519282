import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { MethodologyDesktop } from "./methodology-desktop";
import { MethodologyMobile } from "./methodology-mobile";
import { Banner } from "./banner";
import { BannerMobile } from "./banner-mobile";
const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const Methodology = () => {
  const { desktop, mobile } = useStyles();
  return (
    <>
      <Box className={desktop}>
        <Banner />
        <MethodologyDesktop />
      </Box>
      <Box className={mobile}>
      <BannerMobile />
        <MethodologyMobile />
      </Box>
    </>
  );
};
