import React from "react";
import { Box, Container, Typography, Paper, Grid } from "@mui/material";
import TestimonialBackgroundImage from "../../../assets/testimonial-background-image.png";

import { useTheme } from "@emotion/react";
import { makeStyles } from "@mui/styles";
import { StarIcon } from "../../icons";
import {
    MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBCarouselElement,
    MDBCarouselCaption,
  } from 'mdb-react-ui-kit';
  
const useStyles = makeStyles((theme) => ({
  marqueeContainer: {
    overflow: "hidden",
    display: "flex",
    width: "100%",
    "&:hover": {
      "& > *": {
        animationPlayState: "paused",
      },
    },
  },
  marquee: {
    flex: "0 0 auto",
    zIndex: 1,
    // animation: "$slide 200s infinite linear",
    display: "flex",
    justifyContent: "center",
    minWidth: "100%",
    // "& > *:not(:last-child)": {
    //   marginRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down("sm")]: {
    //   "& > *:not(:last-child)": {
    //     marginRight: theme.spacing(2),
    //   },
    // },
  },
//   "@keyframes slide": {
//     "0%": {
//       transform: "translateX(0%)",
//     },
//     "100%": {
//       transform: "translateX(-100%)",
//     },
//   },
}));
export const TestimonialMobile = () => {
  const { palette } = useTheme();
  const styles = useStyles();
  return (
    <Box minHeight="100vh" position="relative" bgcolor="#F5F7F8">
      <Container maxWidth="sm">
        <Box pt={10} pb={6}>
          <Box>
            <Box width="100%" display="flex" justifyContent="center">
              <Typography variant="h4" color={palette.common.black}>
                What our customers say
              </Typography>
            </Box>
            <Box width="100%" display="flex" justifyContent="center" pt={2}>
              <Box
                bgcolor={palette.secondary.main}
                border={`4px solid ${palette.secondary.main}`}
                width="90px"
                borderRadius="4px"
              />
            </Box>
          </Box>
          <Box>
          <Box pt={4} className={styles.marqueeContainer}>
            <Box className={styles.marquee}>
              <MDBCarousel showControls showIndicators interval={100000000}>
                <MDBCarouselInner>
                <MDBCarouselItem className='active'>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                                      I took my daughter to a weekly tutoring session for most of
                                      her 6th grade year. Over the last 4 months we've switched to
                                      Word Voyage tutoring with wonderful results. For one thing it
                                      is much more convenient and the monthly cost is less than the
                                      weekly cost at the tutoring center. I like Word Voyage's old
                                      school, back to basics approach. On top of this, the tutor has
                                      helped her with grammar and sentence writing. I can't
                                      recommend Word Voyage enough!
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">Molly P.</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">Parent</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                                    Word Voyage has proven an invaluable tool in my classroom as a
                            first-year middle school English teacher. My students are both
                            engaged and challenged by the programs and their vocabulary
                            has flourished. Not only am I able to personalize our lessons
                            to complement the rest of our English curriculum, but Whit
                            responds with lightning fast speed to any questions or issues
                            that arise. I have nothing but the highest praise for this
                            program and Whit.
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">Laura Micetich</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">University School of Jackson, Jackson, TN</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                                    My son just completed 4 months of work with a Word Voyage
                            tutor. The content was definitely challenging for him, but the
                            tutor quickly adjusted the lessons to make them manageable. I
                            loved the instruction in Latin roots and root families- these
                            will be very helpful on the SAT. It's also wonderful to see
                            how much his grammar improved. He's now correcting my grammar
                            all the time! The continual support and positive reinforcement
                            from the tutor was truly remarkable!
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">Cynthia W.</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">Parent</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                                    Thought you'd like to know. Now that kids take a version of PSAT from grades 8-11 we are given info as to how they met the "college readiness" benchmark. All of our classes scored 96% or better on Verbal. 
I could not be more happy with customer service at Word Voyage. They have responded to all my questions, requests, and suggestions with alacrity. I       am amazed at the promptness with which they have created customized word lists on demand for our literature and subject-specific curriculum.
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">John Guevremont</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">English Department Chair, Mount St. Charles Academy Woonsocket, RI</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                          Historically, our students have had relatively low scores on the Vocabulary section of the ELA MAP test, and despite numerous interventions, we haven't had success moving those scores. This year we piloted Word Voyage in 7th grade ELA, and were very impressed with the results. Fall-Winter Vocabulary growth was significantly higher than that of Info/Literacy (5 points of RIT growth). The average vocabulary RIT score is also higher than any other cohort has historically been at this point in their 7th-grade year.
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">Julia Keller</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">English teacher at KIPP Central City, New Orleans</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                          This year we are using Word Voyage for all 7th-9th students and all special education students 7-12. At the half-year point we have seen significant growth in NWEA scores for all students. One teacher indicated: "Wow- every kid has already had a year's growth!” This is just what we needed as we were behind a grade level with one-third of the population. The results are so compelling that next year we will be extending our Word Voyage license to grades 4-9 for all students.
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">Bob England</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">Assistant Principal, Medomak Middle School, Waldoboro, Maine</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                                    My name is Ashley Fitzpatric and I teach 3rd and 4th grade Advanced Language Arts in the Sycamore Community City School District a suburb of Cincinnati, Ohio.  When I read these testimonials I often wonder if it was a real person who actually taught in the classroom year after year and was living with pressures of State Testing and high expectations.  If there ever was such a teacher it is me.  I understand we don't have time for programs that don't make an impact, target standards or that make our kids miserable because that never helped anyone!  I assure you I am real:-)
           I have used Word Voyage for over five years...nearly the beginning of its widespread use on the educational market.  When my colleagues and I started using this product it was so new I could e-mail the creator and he'd e-mail me back within minutes (and he still does for the record).  When you use Word Voyage if you need help, have questions or there is an issue they will be there.  You are not left sorting out yet another program.
          Word Voyage is diverse and incredibly customizable to the academic needs of students as well as the programming you hope to achieve.  It covers roots, word cousins, syllables, definitions, parts of speech, games and the list goes on and on until you are forced to make decisions.  This is why we go back each year.  Teachers can include or exclude all of these parts as needed.  The word lists are rich, quizzes are included, you can weight the work and quizzes as you like and it even grades work/quizzes for you (AMEN!!!)  The best part....to do these things you just click choices on a list-that's it literally.  You don't need more than two minutes.
           I use Word Voyage on a weekly basis.  Students are assigned each unit due in about a week's time so students must manage their time for more than one night.  Although, perhaps not intended, I feel I am able to give students age appropriate executive functioning skills practice as a natural result of implementing Word Voyage.  A win/win for kids and teachers.
           The long term impact of Word Voyage and how it translates to writing and test scores is evident to me through the sentence writing component of Word Voyage.  The sentence writing component requires the students to use the word in a sentence, which I then require to include context clues.  The sentences then must be approved by me before the quiz.  This requirement changes vocabulary/root work instruction from being a memorization activity to being one of application and practice in writing.  A game changer.  This gets at those hard vocabulary questions where kids must use context clues to define words on standardized tests and it provides authentic experience to learning words and being able to really use new vocabulary.  The transformation from the start to end of the year is astounding.
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">Ashley Fitzpatric</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">Sycamore Community City School District, Cincinnati, Ohio</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "150px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "150px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                                    You have been so great with us and we are looking forward to
                    another great year. Guess what? Our ERB Vocabulary scores
                    improved 16% last year!
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">Karen Anderson</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">Wise School, Los Angeles, CA</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
                <MDBCarouselItem>
                  <Box width="100%" margin="auto">
                  <span style={{ fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "-85vw", color: "grey"}}>&#10095;</span>
                    <span style={{fontSize: "30px", width: "100px", height: "200px", position: "relative", top: "280px", right: "4vw", color: "grey"}}>&#10094;</span>
                    <Box width="100%">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            width="90vw"
                            height="570px"
                          >
                            <Box>
                              <Paper style={{width: "90vw", borderRadius: "8px", maxHeight: "567px", overflowY: "auto" }}>
                                <Box
                                  width="90vw"
                                  // height="570px"
                                  px={3}
                                  py={2}
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Box display="flex">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body4">
                                    My district has used Word Voyage with our third and fourth graders in gifted language arts classes.  The vocabulary list is a great mix of words they’ve heard of and new ones to learn about.  We have created our list from the vocabulary given, but I know it can utilize other words connected with certain books or even words of our choice.
Our test scores in vocabulary have gone up.  I love to see how the kids grow in their understanding of the Greek and Latin roots and how they’re the building blocks of so much of the English language.  I love to see the kids understand the ideas of the roots and apply them to what they’re decoding and reading.   “I see the root jud and I know that means judge, so judicious must have something to do with judging.”
Lastly, it is user friendly and doesn’t glitch.  It is very manageable for teachers.  The kids love the games and will challenge each other.  Whit Symmes is the greatest consultant, always answering our questions and being helpful.
I recommend it whole-heartedly.
                                    </Typography>
                                  </Box>
                                  <Box pt={1}>
                                    <Typography variant="body2">LORI KLIMA</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body4">Gifted Specialist, Montgomery Elementary,
Sycamore Community Schools</Typography>
                                  </Box>
                                </Box>
                              </Paper>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </MDBCarouselItem>
              </MDBCarouselInner>
              </MDBCarousel>
              </Box>
          </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
