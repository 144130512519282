import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Typography, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Navbar } from "../header";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100vw",
    backgroundColor: "#34566A",
  },
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const AboutIntroduction = () => {
  const { main, desktop, mobile } = useStyles();
  const { palette } = useTheme();
  return (
    <>
      <Box className={desktop}>
        <Box className={main}>
          <Container maxWidth="lg">
            <Navbar />
            <Box px={12} pb={7} pt={6}>
              <Typography variant="h1" color={palette.common.white}>
                About Us
              </Typography>
              <Typography variant="body3" color={palette.common.white}>
                Word Voyage has been building academic English and grammar software applications since 2010. We are now working with our technology partner Yogaya Inc. to relaunch and expand our product line. With a keen focus on data science, our new applications will support each unique learner with reading, vocabulary growth, spelling, grammar, and writing. In addition to the Word Voyage platform, students will also be able to pursue conversational learning on our Amazon Alexa app.
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
      <Box className={mobile}>
        <Box className={main}>
          <Container maxWidth="sm">
            <Navbar />
            <Box pb={6} pt={6}>
              <Typography variant="h1" color={palette.common.white}>
                About Us
              </Typography>
              <Typography variant="body3" color={palette.common.white}>
                Word Voyage has been building academic English and grammar software applications since 2010. We are now working with our technology partner Yogaya Inc. to relaunch and expand our product line. With a keen focus on data science, our new applications will support each unique learner with reading, vocabulary growth, spelling, grammar, and writing. In addition to the Word Voyage platform, students will also be able to pursue conversational learning on our Amazon Alexa app.
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};
