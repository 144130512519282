import { useTheme } from '@emotion/react';
import { Box, Container, Typography } from '@mui/material';
import logo from '../../assets/wv.svg';
import { Link } from 'react-router-dom';

export const FooterMobile = () => {
  const { palette } = useTheme();
  return (
    <Box position='relative' pb={6} pt={6}>
      <Container maxWidth='sm'>
        <Box>
          <Box display='flex' alignItems='flex-start' flexDirection='column'>
            <Typography
              fontSize='25px'
              fontWeight='bold'
              color={palette.common.white}
            >
              <img style={{ width: '230px' }} src={logo} />
            </Typography>
            <Box pt={8}>
              <Typography variant='h4' color={palette.common.white}>
                Contact
              </Typography>
              <Box pt={1} display='flex' flexDirection='column'>
                <Typography variant='body3' color={palette.common.white}>
                  Email: info@wordvoyage.com
                </Typography>
                <Typography variant='body3' color={palette.common.white}>
                  Phone: +1 (603) 856-0072
                </Typography>
              </Box>
            </Box>
            <Box pt={4}>
              <Typography variant='h4' color={palette.common.white}>
                Mailing Address
              </Typography>
              <Box pt={1} display='flex' flexDirection='column'>
                <Typography variant='body3' color={palette.common.white}>
                  1122 E Pike St
                </Typography>
                <Typography variant='body3' color={palette.common.white}>
                  Box 1176
                </Typography>
                <Typography variant='body3' color={palette.common.white}>
                  Seattle, WA 98122 - United States
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box pt={8} display='flex' justifyContent='center'>
            <Typography variant='body3' color='rgba(255, 255, 255, 0.502972)'>
              <a href='/privacy-policy'>
                {' '}
                <Typography
                  variant='body3'
                  color={palette.common.white}
                  style={{ paddingRight: '5px' }}
                >
                  Privacy Policy
                </Typography>
              </a>{' '}
              © {new Date().getFullYear()} Adelja Learning, Inc.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
