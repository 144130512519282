import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Navbar } from "../header";
import { Footer } from "../footer";
import { SchoolBody } from "./school-body";
import { SchoolIntroduction } from "./school-introduction";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100vw",
    backgroundColor: "#34566A",
  },
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
export const School = () => {
  const { main, desktop, mobile } = useStyles();
  const [grade, setGrade] = useState("4-5");
  return (
    <>
      <Box className={desktop}>
        <Box className={main}>
          <Container maxWidth="lg">
            <Navbar />
            <SchoolIntroduction grade={grade} setGrade={setGrade} />
          </Container>
        </Box>
        <SchoolBody grade={grade} />
        <Footer />
      </Box>

      <Box className={mobile}>
        <Box className={main}>
          <Container maxWidth="lg">
            <Navbar />
            <SchoolIntroduction grade={grade} setGrade={setGrade} />
          </Container>
        </Box>
        <SchoolBody grade={grade} />
        <Footer />
      </Box>
    </>
  );
};
