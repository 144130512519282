import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import GradeNineToTwelve from "../../assets/grade-nine-to-twelve.png";

export const SchoolBodyGradeNineToTwelveContent = () => {
  const { palette } = useTheme();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      <Typography variant="h3">
        TARGETING THE SKILL GAPS THAT HOLD STUDENTS BACK
      </Typography>

      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "56px" : "80px", paddingTop: "32px" }}
      >
        Word Voyage provides an <span style={{fontWeight: "600", color: "black"}}>efficient platform to triage grammar and
        vocabulary deficiencies</span>. You can build custom lessons to target skill
        gaps with 15 minutes of daily practice. Many times students are in the
        ballpark and just need more regular, focused time-on-task. Word Voyage
        brings them over the finish line!
      </Typography>
      <img
        width="100%"
        height="auto"
        src={GradeNineToTwelve}
        alt={"Literature-Based Vocabulary"}
        loading="lazy"
      />
      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        SAT AND ACT PREPARATION
      </Typography>
      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage zeros in on the skills needed to score well on college-entry
        tests. The program <span style={{fontWeight: "600", color: "black"}}>reinforces the word-attack and grammar skills that
        improve scores</span>. Using Word Voyage regularly for 2 to 3 months will
        deliver measurable growth.
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        LITERATURE-BASED VOCABULARY
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage marries vocabulary instruction to reading content. What
        books are you assigning? The program includes hundreds of lists from
        classic and popular titles. Word Voyage students study <span style={{fontWeight: "600", color: "black"}}>the vocabulary
        <span style={{fontWeight: "400", color: "#FFCD00", fontStyle: "italic"}}> they need now</span>.</span>
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        NOTHING LEFT TO CHANCE
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        To be successful and meet their goals, <span style={{fontWeight: "600", color: "black"}}>students must be able to
        represent themselves in writing</span>. Nothing undermines this effort like
        sloppy grammar or inaccurate word usage. Word Voyage delivers the
        practice and guidance students need to become impactful writers. <span style={{fontWeight: "400", color: "#FFCD00", fontStyle: "italic"}}>Details
        matter</span>!
      </Typography>
    </>
  );
};
